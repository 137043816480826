<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="home">
    <h1>可解释深度学习技术在医疗行业中的应用</h1>
    <p class="company-intro" style="white-space: pre-wrap;">
      {{ companyIntro }}
    </p>
    <h1>产品应用场景</h1>
    <div class="applied-area">
      <div class="area shadow-lg" v-for="area in appliedArea" :key="area">
        {{ area }}
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    const companyIntro =
      "\t世象医疗科技，专注于医学人工智能领域，团队具有强大的机器学习、自然语言处理和计算机视觉等方面的技术能力，拥有深厚的临床医学及影像学研究背景，研究成果曾多次在CVPR，ICCV等国际顶级计算机视觉会议上发表。通过多年的研究和实践，我们在大语言模型和医学图像解析等方面取得了显著成果。团队自主研发了一系列医用智能系统，致力于提升基层医院诊断水平，促进分级诊疗的推广。\n\t赋能基层诊疗服务，助力智慧医疗发展。";
    const appliedArea = [
      "远程医疗服务",
      "医学教育和培训",
      "健康管理应用",
      "医院信息系统",
      "在线医疗咨询平台",
      "客户服务自动化",
    ];
    return { store, companyIntro, appliedArea };
  },
  computed: {
    computedUser() {
      console.log("store", this.store);
      return new URL(this.store.state.user, import.meta.url).href;
    },
  },
  methods: {},
};
</script>
<style>
.home {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  h1 {
    width: 30%;
    text-align: center;
  }
  p {
    font-size: 20px;
  }
}

.area {
  border: 1px solid lightgray;
  padding: 15px;
  border-radius: 10px;
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 10px;
}
.area:hover {
  background-color: var(--bs-primary);
  color: whitesmoke;
  transition: background-color 0.5s;
}

.company-intro {
  width: 80%;
}

.router-wrapper {
  margin-top: 20px;
  width: 40%;
  min-width: 300px;
  height: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.applied-area {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
}

.router-link {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.router-link:active {
  background: whitesmoke;
}
</style>
