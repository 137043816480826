<template>
  <div id="app">
    <custom-header />
    <custom-content />
    <custom-footer />
  </div>
</template>

<script setup>
import customHeader from "./layout/custom-header.vue";
import customContent from "./layout/custom-content.vue";
import customFooter from "./layout/custom-footer.vue";
import { onMounted } from "vue";
onMounted(() => {
  window.addEventListener("touchmove", function (e) {
    e.preventDefault();
  });
  window.addEventListener("touchstart", function (e) {
    e.preventDefault();
  });
});
</script>

<style>
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: none;
  ::-webkit-scrollbar {
    display: none;
  }
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
}

#app {
  width: 100vw;
  height: 100vh;
}
</style>
