import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/home.vue";

const router = createRouter({
  history: createWebHistory(), // history 模式
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        title: "世象医疗",
      },
    },
    {
      path: "/products",
      name: "products",
      component: () => import(`../views/products.vue`),
      meta: {
        title: "产品描述",
      },
    },
    {
      path: "/chart",
      name: "chart",
      component: () => import(`../views/chart.vue`),
      meta: {
        title: "描述诊断",
      },
    },
    {
      path: "/chat",
      name: "chat",
      component: () => import(`../views/chat.vue`),
      meta: {
        title: "在线诊断",
      },
    },
    {
      path: "/*",
      redirect: "/",
    },
  ],
});

// 全局路由守卫
router.beforeEach((to, from, next) => {
  // console.log(to, from)
  if (to.meta.title) {
    document.title = `${to.meta.title}`;
    window.document.title = `${to.meta.title}`;
  }
  next();
});

router.afterEach((to, from) => {
  console.log(to, from);
  console.log("afterEach");
});

export default router;
