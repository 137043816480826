<template>
  <div class="header shadow-sm">
    <img src="@/assets/logo_full.jpg" style="float: left;" />
    <div @click="goTo('/')" class="nav-item">
      关于我们
    </div>
    <div class="nav-item" @click="goTo('products')">我们的产品</div>
    <div
      class="nav-item products-btn dropdown-toggle"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      试用产品
      <ul class="dropdown-menu dropdown-menu-light">
        <li class="dropdown-item" @click="goTo('chart')">
          1、一种基于医学图像概念的可解释诊断报告生成方法
        </li>
        <li class="dropdown-item" @click="goTo('chat')">
          2、通过大型语言模型进行迭代感知和推理来解析临床记录
        </li>
        <li class="dropdown-item">
          3、基于偏好优化的用户医学询问质量改进方法及系统
        </li>
      </ul>
    </div>
    <img class="user-avatar" :src="store.state.user" v-if="store.state.user" />
    <button
      type="button"
      class="btn btn-primary"
      data-bs-toggle="modal"
      data-bs-target="#exampleModal"
      v-else
    >
      登录
    </button>
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">
              登录到世象科技
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form>
              <div class="mb-3">
                <label for="recipient-name" class="col-form-label"
                  >用户名:</label
                >
                <input type="text" class="form-control" id="recipient-name" />
              </div>
              <div class="mb-3">
                <label for="recipient-name" class="col-form-label">密码:</label>
                <input
                  type="password"
                  class="form-control"
                  id="recipient-password"
                />
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              @click="login()"
            >
              登录
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
const store = useStore();
const router = useRouter();
function login() {
  let gender, user, userAvatar;
  const i = Math.ceil(Math.random() * 2);
  const j = Math.ceil(Math.random() * 2);
  if (i < 2) {
    gender = "female";
  } else {
    gender = "male";
  }
  if (j < 2) {
    user = "1";
  } else {
    user = "2";
  }
  userAvatar = "/me_" + gender + user + ".png";
  store.commit("login", userAvatar);
}
function goTo(view) {
  router.push({
    path: view,
  });
}
</script>
<style>
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  height: 100px;
  border-bottom: 2px solid whitesmoke;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  img {
    width: 20%;
    min-width: 100px;
    height: 60%;
    object-fit: contain;
    object-position: left;
  }
  button {
    margin-left: auto;
  }
  .user-avatar {
    margin-left: auto;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    object-fit: contain;
  }
}

.products-btn {
  border: none;
  background-color: transparent;
  font-size: 20px;
}

.nav-item {
  font-size: 20px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.nav-item:hover {
  color: var(--bs-primary);
}
</style>
