<template
  ><div class="content"><router-view></router-view></div
></template>
<script></script>
<style>
.content {
  width: 100%;
  height: calc(100vh - 100px);
  position: absolute;
  top: 100px;
  left: 0px;
}
</style>
